import React from "react";
import { Switch, Route, useHistory } from "react-router-dom";

// components
import FooterAdmin from "components/Footers/FooterAdmin.js";
import { getParamValue } from "utils/webutils";

// views

import LeaderboardPage from "views/leaderboard/Dashboard";
import Navbar from "components/Navbars/IndexNavbar";
import LeaderboardGuildData from "components/Headers/LeaderboardGuildData";
import GlobalLeaderboardPage from "views/leaderboard/Global";

export default function Leaderboard() {
  const history = useHistory();
  if(getParamValue(window.location.search) == null) {
    if(window.location.pathname !== "/leaderboard/global") {
      history.push("/?lbErrorModal=true");
      return <></>;
    }
  }


  const queryParams = new URLSearchParams(window.location.search);
  if (getParamValue(window.location.search) != null) {
    if(queryParams.get("guildId") === "") {
      history.push("/?lbErrorModal=true");
      return <></>;
    }
    document.cookie = "guildId=" + queryParams.get("guildId") + ";";
  }

  return (
    <>
      <div className="relative bg-blueGray-100">
        <Navbar />
        <LeaderboardGuildData />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path="/leaderboard/guild" component={LeaderboardPage} />
            <Route path="/leaderboard/global" component={GlobalLeaderboardPage} />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
