import React from "react";
import { Switch, Route } from "react-router-dom";

// components

import PanelNavbar from "components/Navbars/PanelNavbar.js";
import PanelHeaderStats from "components/Headers/PanelHeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/panel/Dashboard.js";
import Sidebar from "components/Sidebar/Sidebar";

export default function Volcanoids() {
  return (
    <>
    <Sidebar />
      <div className="relative bg-blueGray-100">
        <PanelNavbar />
        {/* Header */}
        <PanelHeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path="/volcanoids/rich" exact component={Dashboard} />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
