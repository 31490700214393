import React from "react";
import Chart from "chart.js";
import { getCookieValue, getParamValue } from "utils/webutils";
import { getFromWebserver } from "utils/webserver";

export default function CardBarChart() {
  let dtas = [];
  let dtag = [];
  let updated = false;

  const queryParams = new URLSearchParams(window.location.search);
  if (getParamValue(window.location.search) != null) {
    document.cookie =
      "userId=" +
      queryParams.get("id") +
      "; confirmation=" +
      queryParams.get("confirmation") +
      ";";
  }

  async function startDataBuild() {
    await getFromWebserver(`webusers/${getCookieValue("userId")}`).then((u) => {
      console.log(u.data)
      buildData(u.data);
    });
  }

  async function buildData(user) {
    if (user != null) {
      user.guilds
        .forEach((g) => {
          if (g.xp > 0) {
            var gdobj = {
              label: g.name,
              backgroundColor: "#406ae6",
              borderColor: "#406ae6",
              data: [g.level],
              fill: false,
              barThickness: 10,
            };
            dtag.push(g.name);
            dtas.push(gdobj);
          }
        })
        finishDataBuild(dtas, dtag);
    } else {
      user.data.guilds.forEach((g) => {
        if (g.xp > 0) {
          var gdobj = {
            label: g.name,
            backgroundColor: "#406ae6",
            borderColor: "#406ae6",
            data: [g.level],
            fill: false,
            barThickness: 10,
          };
          dtag.push(g.name);
          dtas.push(gdobj);
        }
      })
      finishDataBuild(dtas, dtag);
    }
  }

  async function finishDataBuild(d, dt) {
    let config = {
      type: "bar",
      data: {
        labels: dt,
        datasets: d,
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: "Level Chart",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        legend: {
          labels: {
            fontColor: "rgba(0,0,0,.4)",
          },
          align: "end",
          position: "bottom",
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Server",
              },
              gridLines: {
                borderDash: [2],
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.3)",
                zeroLineColor: "rgba(33, 37, 41, 0.3)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Level",
              },
              gridLines: {
                borderDash: [2],
                drawBorder: false,
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.2)",
                zeroLineColor: "rgba(33, 37, 41, 0.15)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
              ticks: {
                stepSize: 1
              }
            },
          ],
        },
      },
    };
    let ctx = document.getElementById("bar-chart").getContext("2d");
    window.myBar = new Chart(ctx, config);
    completeDataBuild();
  }

  startDataBuild();

  async function completeDataBuild() {
    if (!updated) {
      window.myBar.data.datasets = dtas;
      window.myBar.update();
      updated = true;
    }
  }

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
                Levels
              </h6>
              <h2 className="text-blueGray-700 text-xl font-semibold">
                Levels by Server
              </h2>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto">
          {/* Chart */}
          <div className="relative h-350-px">
            <canvas id="bar-chart"></canvas>
          </div>
        </div>
      </div>
    </>
  );
}
