import axios from 'axios';

let baseurl = "https://capi.melodicalbuild.com/api/"
let token = "";

export async function authorizeWebserver() {
    let response = await axios.post(baseurl + "auth/jwt", {
        username: "Cap-2.0-Web"
    });
    token = response.data.token;
    document.cookie = "webtoken=" + token + ";"
}

export async function getFromWebserver(url) {
    if(token === "") await authorizeWebserver();
    let response = await axios.get(baseurl + url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
}