import React, { useState, useCallback, useEffect } from "react";
import { getCookieValue, getParamValue } from "utils/webutils";
import { getFromWebserver } from "utils/webserver";

// components

import CardStats from "components/Cards/CardStats.js";

export default function PanelHeaderStats() {
  const queryParams = new URLSearchParams(window.location.search);
  if (getParamValue(window.location.search) != null) {
    document.cookie =
      "userId=" +
      queryParams.get("id") +
      "; confirmation=" +
      queryParams.get("confirmation") +
      ";";
  }

  const [data, dataSet] = useState();
  const [totalXp, setTotalXp] = useState();
  const [totalXpString, setTotalXpString] = useState();
  const [lastTotalXp, setLastTotalXp] = useState();
  const [lastTotalXpString, setLastTotalXpString] = useState();
  const [totalServers, setTotalServers] = useState();
  const [totalBotServers, setTotalBotServers] = useState();

  const fetchMyAPI = useCallback(async () => {
    let response = await getFromWebserver(
      `webusers/${getCookieValue("userId")}`
    );
    dataSet(response.data);
    let val = 0;
    response.data.guilds.forEach((g) => {
      val += g.totalXp;
    });
    setTotalXp(val);
    setTotalXpString(val.toString());
    let val2 = 0;
    response.data.guilds.forEach((g) => {
      val2 += g.totalXpLastWeek;
    });
    setLastTotalXp(val2);
    setLastTotalXpString(val2.toString());
    setTotalServers(response.data.numGuilds.toString());
    setTotalBotServers(response.data.numBotGuilds.toString());
  }, []);

  useEffect(() => {
    fetchMyAPI();
  }, [fetchMyAPI]);

  return (
    <>
      {/* Header */}
      <div className="relative bg-lightBlue-600 md:pt-32 pb-32 pt-12">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Total XP"
                  statTitle={totalXpString}
                  statArrow={totalXp > lastTotalXp ? "up" : "down"}
                  statPercent={(totalXp / lastTotalXp / 10).toString()}
                  statPercentColor={
                    totalXp > lastTotalXp ? "text-emerald-500" : "text-red-500"
                  }
                  statDescripiron="Since last week"
                  statIconName="fa-solid fa-gear"
                  statIconColor="bg-lightBlue-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Total XP Last Week"
                  statTitle={lastTotalXpString}
                  statArrow={totalXp > lastTotalXp ? "up" : "down"}
                  statPercent={(totalXp / lastTotalXp / 10).toString()}
                  statPercentColor={
                    totalXp > lastTotalXp ? "text-emerald-500" : "text-red-500"
                  }
                  statDescripiron="Since the previous week"
                  statIconName="fas fa-arrow-left-long"
                  statIconColor="bg-red-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Bot Servers"
                  statTitle={totalBotServers}
                  statArrow=""
                  statPercent="0"
                  statPercentColor="text-blueGrey-500"
                  statDescripiron="Overall"
                  statIconName="fa-brands fa-bots"
                  statIconColor="bg-emerald-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Total Servers"
                  statTitle={totalServers}
                  statArrow=""
                  statPercent="0"
                  statPercentColor="text-blueGrey-500"
                  statDescripiron="Overall"
                  statIconName="fas fa-building-columns"
                  statIconColor="bg-teal-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
