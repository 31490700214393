import React, { useState, useCallback, useEffect } from "react";
import { getCookieValue, getParamValue } from "utils/webutils";
import { getFromWebserver } from "utils/webserver";

// components

export default function CardPageVisits() {
  const [data, dataSet] = useState();
  const [tableData, setTableData] = useState();
  const [globalData, setGlobalData] = useState();
  const [globalDataCount, setGlobalDataCount] = useState();

  const queryParams = new URLSearchParams(window.location.search);
  if (getParamValue(window.location.search) != null) {
    document.cookie =
      "userId=" +
      queryParams.get("id") +
      "; confirmation=" +
      queryParams.get("confirmation") +
      ";";
  }

  const fetchMyAPI = useCallback(async () => {
    let tableArray = [];
    let response = await getFromWebserver(
      `leaderboard/${getCookieValue("userId")}`
    );
    let response2 = await getFromWebserver(
      `global/leaderboard/${getCookieValue("userId")}`
    );
    let response3 = await getFromWebserver(
      `global/leaderboard/`
    );
    setGlobalDataCount(response3.data.length.toString())
    setGlobalData(response2.data.data.position.toString())
    dataSet(response.data);
    response.data.forEach((item) => {
      tableArray.push(
        <tr key={item.guild.name}>
          <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
            <a
              href={`https://captain.melodicalbuild.com/leaderboard/guild?guildId=${item.guild.guildId}`}
            >
              {item.guild.name}
            </a>
          </th>
          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {
              item.leaderboard.find(
                (x) => x.userId === getCookieValue("userId")
              ).position
            }{" "}
            / {item.leaderboard.length}
          </td>
          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {
              item.leaderboard.find(
                (x) => x.userId === getCookieValue("userId")
              ).xp.level
            }
          </td>
          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {
              item.leaderboard.find(
                (x) => x.userId === getCookieValue("userId")
              ).xp.xp
            }
          </td>
        </tr>
      );
    })
    setTableData(tableArray);
  }, []);

  useEffect(() => {
    fetchMyAPI();
  }, [fetchMyAPI]);

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                Leaderboard
              </h3>
            </div>
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
              <h3 className="font-semibold text-base text-blueGray-700">
                Your Global Position:{" "}
                <span className="text-emerald-500">{globalData}</span> /{" "}
                {globalDataCount}
              </h3>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Guild Name
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Your Position
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Your Level
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Your XP
                </th>
              </tr>
            </thead>
            <tbody>{tableData}</tbody>
          </table>
        </div>
      </div>
    </>
  );
}
