export function getCookieValue(name) {
  var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  if (match) {
    return match[2];
  } else {
    console.log("--something went wrong---");
  }
}

export function getParamValue(value) {
    var match = value.replace(new RegExp("\\?[^=]+="), '');
    if (match) {
      return match;
    } else {
      console.log("--something went wrong---");
    }
}