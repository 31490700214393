import React, { useState, useCallback, useEffect } from "react";
import { getCookieValue, getParamValue } from "utils/webutils";
import { getFromWebserver } from "utils/webserver";

// components

import CardStatsBlank from "components/Cards/CardStatsBlank";

export default function LeaderboardGuildData() {
  const queryParams = new URLSearchParams(window.location.search);
  if (getParamValue(window.location.search) != null) {
    document.cookie =
      "guildId=" +
      queryParams.get("guildId") +
      ";";
  }

  const [data, dataSet] = useState();
  const [guildName, setGuildName] = useState();
  const [guildUsers, setGuildUsers] = useState();
  const [guildChannels, setGuildChannels] = useState();
  const [guildPrefix, setGuildPrefix] = useState();

  const fetchMyAPI = useCallback(async () => {
    if(window.location.pathname !== "/leaderboard/global") {
      let response = await getFromWebserver(
        `guild/${getCookieValue("guildId")}`
      );
      dataSet(response.data);
      setGuildName(response.data.guildName);
      setGuildUsers(response.data.numUsers.toString());
      setGuildChannels(response.data.channels.length.toString());
      setGuildPrefix(response.data.prefix);
    }
  }, []);

  useEffect(() => {
    fetchMyAPI();
  }, [fetchMyAPI]);

  if (window.location.pathname === "/leaderboard/global") {
    return (
      <>
        {/* Header */}
        <div className="relative bg-lightBlue-600 md:pt-32 pb-32 pt-12">
          <div className="px-4 md:px-10 mx-auto w-full">
            <div>
              {/* Card stats */}
              <div className="flex flex-wrap"></div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {/* Header */}
      <div className="relative bg-lightBlue-600 md:pt-32 pb-32 pt-12">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStatsBlank
                  statSubtitle="Guild Name"
                  statTitle={guildName}
                  statArrow="blank"
                  statPercent=""
                  statPercentColor=""
                  statDescripiron=""
                  statIconName="fa-solid fa-gear"
                  statIconColor="bg-lightBlue-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStatsBlank
                  statSubtitle="Guild Users"
                  statTitle={guildUsers}
                  statArrow="blank"
                  statPercent=""
                  statPercentColor=""
                  statDescripiron=""
                  statIconName="fa-solid fa-gear"
                  statIconColor="bg-lightBlue-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStatsBlank
                  statSubtitle="Guild Channels"
                  statTitle={guildChannels}
                  statArrow="blank"
                  statPercent=""
                  statPercentColor=""
                  statDescripiron=""
                  statIconName="fa-solid fa-gear"
                  statIconColor="bg-lightBlue-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStatsBlank
                  statSubtitle="Guild Prefix"
                  statTitle={guildPrefix}
                  statArrow="blank"
                  statPercent=""
                  statPercentColor=""
                  statDescripiron=""
                  statIconName="fa-solid fa-gear"
                  statIconColor="bg-lightBlue-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
