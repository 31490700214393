import React, { useState, useCallback, useEffect } from "react";
import { getCookieValue, getParamValue } from "utils/webutils";
import { getFromWebserver } from "utils/webserver";

// components

export default function LeaderboardCard() {
  const [data, dataSet] = useState();
  const [tableData, setTableData] = useState();
  const [guildName, setGuildName] = useState();

  const queryParams = new URLSearchParams(window.location.search);
  if (getParamValue(window.location.search) != null) {
    document.cookie =
      "guildId=" +
      queryParams.get("guildId") +
      ";";
  }

  const fetchMyAPI = useCallback(async () => {
    let tableArray = [];
    let response = await getFromWebserver(
      `guilds/leaderboard/${getCookieValue("guildId")}`
    );
    dataSet(response.data);
    setGuildName(response.data.guild.name)
    var list = response.data.leaderboard;
    list.sort(function (a, b) {
      return a.position - b.position;
    });
    list.forEach((item) => {
      tableArray.push(
        <tr key={item.userId}>
          <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
            {item.name}
          </th>
          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {item.position} / {response.data.leaderboard.length}
          </td>
          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {item.xp.level}
          </td>
          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {item.xp.xp}
          </td>
        </tr>
      );
    });
    setTableData(tableArray);
  }, []);

  useEffect(() => {
    fetchMyAPI();
  }, [fetchMyAPI]);

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                {guildName}
              </h3>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Name
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Position
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Level
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  XP
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
