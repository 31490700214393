/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// components

import IndexDropdown from "components/Dropdowns/IndexDropdown.js";
import { getCookieValue } from "utils/webutils";
import { getParamValue } from "utils/webutils";

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  let userSignedIn = false;

  let button = <></>
  let dashboard = <></>

  async function rebuildNavbar() {
    if(!userSignedIn) {
      dashboard = <></>;
      return;
    }
    if (userSignedIn) {
      dashboard = (
        <li className="flex items-center">
          <a
            className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
            href="/admin/dashboard"
          >
            <i className="fa-solid fa-wrench" />{"  "}Dashboard
          </a>
        </li>
      );
    }
  }

  async function rebuildButton() {
    if (userSignedIn) {
      button = (
        <a
          href="http://capi.melodicalbuild.com/auth/login"
          target="_blank"
          rel="noreferrer"
        >
          <button
            className="bg-blueGray-700 text-white active:bg-blueGray-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              userSignedIn = false;
            }}
          >
            {" "}
            <i className="fas fa-user"></i> Sign Out{" "}
          </button>
        </a>
      );
    } else {
      button = (
        <a href="http://capi.melodicalbuild.com/auth/login">
          <button
            className="bg-blueGray-700 text-white active:bg-blueGray-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
            type="button"
            onClick={(e) => {
              userSignedIn = true;
            }}
          >
            <i className="fas fa-user"></i> Sign In
          </button>
        </a>
      );
    }
  }

  rebuildButton();
  rebuildNavbar();

  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              to="/"
              className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            >
              Cap 2.0
            </Link>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none mr-auto">
              <li className="flex items-center">
                <a
                  className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="https://www.creative-tim.com/learning-lab/tailwind/react/overview/notus?ref=nr-index-navbar"
                >
                  <i className="text-blueGray-400 far fa-file-alt text-lg leading-lg mr-2" />{" "}
                  Docs
                </a>
              </li>
            </ul>
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <Link
                  to="/aboutus"
                  className="text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                >
                  About Us
                </Link>
              </li>

              {dashboard}

              <li className="flex items-center">
                <a
                  className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="https://discord.gg/vcUsSWP"
                  target="_blank"
                >
                  <i className="text-blueGray-400 fab fa-discord text-lg leading-lg " />
                  <span className="lg:hidden inline-block ml-2">Discord</span>
                </a>
              </li>

              <li className="flex items-center">
                <a
                  className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="https://github.com/Hab3925/Cap-2.0"
                  target="_blank"
                >
                  <i className="text-blueGray-400 fab fa-github text-lg leading-lg " />
                  <span className="lg:hidden inline-block ml-2">Star</span>
                </a>
              </li>

              <li className="flex items-center">{button}</li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
