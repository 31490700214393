import React, {useState, useCallback, useEffect} from "react";

// components

import { getFromWebserver } from "utils/webserver";
import GlobalLeaderboardCard from "components/Cards/GlobalLeaderboardCard";

export default function GlobalLeaderboardPage() {

  const [data, dataSet] = useState();

  const fetchMyAPI = useCallback(async () => {
    let response = await getFromWebserver(
      `global/leaderboard/`
    );
    dataSet(response.data);
  }, []);

  useEffect(() => {
    fetchMyAPI();
  }, [fetchMyAPI]);

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
          <GlobalLeaderboardCard />
        </div>
      </div>
    </>
  );
}
