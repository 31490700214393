import React from "react";
import Chart from "chart.js";

import { getParamValue } from "utils/webutils";
import { getFromWebserver } from "utils/webserver";

export default function CardLineChart() {
  let dtas = [];
  let clr = [];
  let updated = false;

  const queryParams = new URLSearchParams(window.location.search);
  if (getParamValue(window.location.search) != null) {
    document.cookie =
      "userId=" +
      queryParams.get("id") +
      "; confirmation=" +
      queryParams.get("confirmation") +
      ";";
  }

  async function startDataBuild() {
    await getFromWebserver(`web/guilds/562836027522154498`).then((u) => {
      console.log(u)
      buildData(u.data);
    });
  }

  async function buildData(user) {
    if (user != null) {
      user.userData.forEach((y) => {
        var data = []
        y.months.forEach((m) => {
          data.push(m.users)
          clr.push(m.month)
        })
        var obj = {
          label: y.year,
          backgroundColor: y.color,
          borderColor: y.color,
          data: data,
          fill: false
        }
        dtas.push(obj)
      finishDataBuild(dtas, clr);
    })
  }}

  async function finishDataBuild(d, clr) {
    var config = {
      type: "line",
      data: {
        labels: clr,
        datasets: [d],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: "Sales Charts",
          fontColor: "white",
        },
        legend: {
          labels: {
            fontColor: "white",
          },
          align: "end",
          position: "bottom",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: "rgba(255,255,255,.7)",
              },
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Month",
                fontColor: "white",
              },
              gridLines: {
                display: false,
                borderDash: [2],
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.3)",
                zeroLineColor: "rgba(0, 0, 0, 0)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontColor: "rgba(255,255,255,.7)",
              },
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Users",
                fontColor: "white",
              },
              gridLines: {
                borderDash: [3],
                borderDashOffset: [3],
                drawBorder: false,
                color: "rgba(255, 255, 255, 0.15)",
                zeroLineColor: "rgba(33, 37, 41, 0)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
        },
      },
    };
    var ctx = document.getElementById("line-chart").getContext("2d");
    window.myLine = new Chart(ctx, config);
    completeDataBuild();
  }

  startDataBuild();

  async function completeDataBuild() {
    if (!updated) {
      window.myLine.data.datasets = dtas;
      window.myLine.update();
      updated = true;
    }
  }
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-700">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-100 mb-1 text-xs font-semibold">
                Server Data
              </h6>
              <h2 className="text-white text-xl font-semibold">Server Data Information</h2>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto">
          {/* Chart */}
          <div className="relative h-350-px">
            <canvas id="line-chart"></canvas>
          </div>
        </div>
      </div>
    </>
  );
  }