import React, { useState, useCallback, useEffect } from "react";
import { getParamValue } from "utils/webutils";
import { getFromWebserver } from "utils/webserver";

// components

export default function GlobalLeaderboardCard() {
  const [data, dataSet] = useState();
  const [globalTableData, setGlobalTableData] = useState();

  const queryParams = new URLSearchParams(window.location.search);
  if (getParamValue(window.location.search) != null) {
    document.cookie =
      "guildId=" +
      queryParams.get("guildId") +
      ";";
  }

  const fetchMyAPI = useCallback(async () => {
    let tableArray = [];
    let response = await getFromWebserver(
      `global/leaderboard`
    );
    dataSet(response.data);
    var list = response.data;
    list.sort(function(a,b){return a.data.position - b.data.position});
    list.forEach((item) => {
      tableArray.push(<tr key={item.data.userId + " " + item.data.name}>
        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
          {item.data.name}
        </th>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
          {
            item.data.position
          } / {response.data.length}
        </td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
          {
            item.data.xp.level
          }
        </td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
          {
            item.data.xp.xp
          }
        </td>
      </tr>);
    })
    setGlobalTableData(tableArray);
  }, []);

  useEffect(() => {
    fetchMyAPI();
  }, [fetchMyAPI]);

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                Global Leaderboard
              </h3>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Name
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Position
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Level
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  XP
                </th>
              </tr>
            </thead>
            <tbody>
              {globalTableData}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
