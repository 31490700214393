import React, { useState, useCallback, useEffect } from "react";
import { getCookieValue, getParamValue } from "utils/webutils";
import { getFromWebserver } from "utils/webserver";

// components

import CardStats from "components/Cards/CardStats.js";

export default function BotStats() {
  const queryParams = new URLSearchParams(window.location.search);
  if (getParamValue(window.location.search) != null) {
    document.cookie =
      "userId=" +
      queryParams.get("id") +
      "; confirmation=" +
      queryParams.get("confirmation") +
      ";";
  }

  const [data, dataSet] = useState();
  const [botId, setBotId] = useState();
  const [numServers, setNumServers] = useState();
  const [numUsers, setNumUsers] = useState();
  const [numCommands, setNumCommands] = useState();

  const fetchMyAPI = useCallback(async () => {
    let response = await getFromWebserver(`bots/562837202296635393`);
    dataSet(response.data);
    setBotId(response.data.botId.toString())
    setNumServers(response.data.numServers.toString())
    setNumUsers(response.data.numUsers.toString());
    setNumCommands(response.data.numCommands.toString())
  }, []);

  useEffect(() => {
    fetchMyAPI();
  }, [fetchMyAPI]);

  return (
    <>
      {/* Header */}
      <div className="relative md:pt-10 pb-16">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Bot ID"
                  statTitle={botId}
                  statArrow=""
                  statPercent=""
                  statPercentColor="text-emerald-500"
                  statDescripiron=""
                  statIconName="fa-solid fa-id-badge"
                  statIconColor="bg-lightBlue-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Bot in Servers"
                  statTitle={numServers}
                  statArrow=""
                  statPercent=""
                  statPercentColor="text-emerald-500"
                  statDescripiron=""
                  statIconName="fa-solid fa-building-columns"
                  statIconColor="bg-red-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Users using Bot"
                  statTitle={numUsers}
                  statArrow=""
                  statPercent=""
                  statPercentColor="text-blueGrey-500"
                  statDescripiron=""
                  statIconName="fa-solid fa-users"
                  statIconColor="bg-emerald-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Total Bot Commands"
                  statTitle={numCommands}
                  statArrow=""
                  statPercent=""
                  statPercentColor="text-blueGrey-500"
                  statDescripiron=""
                  statIconName="fa-solid fa-terminal"
                  statIconColor="bg-teal-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
