import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

// components

import CardBarChart from "components/Cards/CardBarChart.js";
import CardPageVisits from "components/Cards/CardPageVisits.js";
import { getParamValue } from "utils/webutils";
import { getCookieValue } from "utils/webutils";
import { getFromWebserver } from "utils/webserver";

export let UserData;

export default function Dashboard() {
  const [data, dataSet] = useState();

  const queryParams = new URLSearchParams(window.location.search);

  const history = useHistory();

  if (!queryParams.has("confirmation")) {
    history.push("/panel/dashboard?id=" + queryParams.get("id"));
  }

  if (getParamValue(window.location.search) != null) {
    document.cookie =
      "userId=" +
      queryParams.get("id") +
      "; confirmation=" +
      queryParams.get("confirmation") +
      ";";
  }

  const fetchMyAPI = useCallback(async () => {
    let response = await getFromWebserver(
      `webusers/${getCookieValue("userId")}`
    );
    UserData = response.data;
    dataSet(response.data);
  }, []);

  useEffect(() => {
    fetchMyAPI();
  }, [fetchMyAPI]);

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-12/12 px-4">
          <CardBarChart />
        </div>
      </div>
      <div className="flex flex-wrap mt-4">
        <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
          <CardPageVisits />
        </div>
      </div>
    </>
  );
}
