import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-svg-core/styles.css";
import "assets/styles/tailwind.css";

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";
import Panel from "layouts/Panel.js";

// views without layouts

import Landing from "views/Landing.js";
import Profile from "views/Profile.js";
import Index from "views/Index.js";
import { authorizeWebserver } from "utils/webserver";
import Leaderboard from "layouts/Leaderboard";
import Volcanoids from "layouts/Volcanoids";

async function buildWebserver() {
  await authorizeWebserver();
}

buildWebserver();

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* add routes with layouts */}
      <Route path="/admin" component={Admin} />
      <Route path="/auth" component={Auth} />
      <Route path="/panel" component={Panel} />
      <Route path="/leaderboard" component={Leaderboard} />
      <Route path="/volcanoids" component={Volcanoids} />
      {/* add routes without layouts */}
      <Route path="/aboutus" exact component={Landing} />
      <Route path="/" exact component={Index} />
      <Route
        exact
        path="/privacy-policy"
        render={() => {
          window.location.href = "privacy-policy.html";
        }}
      />
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
