/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { getParamValue } from "utils/webutils";

import UserDropdown from "components/Dropdowns/UserDropdown.js";

export default function Navbar() {
  const queryParams = new URLSearchParams(window.location.search);
  if (getParamValue(window.location.search) != null) {
    document.cookie =
      "userId=" +
      queryParams.get("id") +
      "; confirmation=" +
      queryParams.get("confirmation") +
      ";";
  }

  return (
    <>
      {/* Navbar */}
      <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
        <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
          {/* Brand */}
          <a
            className="text-white text-sm uppercase hidden lg:inline-block font-semibold"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            Dashboard
          </a>
          {/* User */}
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
            <UserDropdown />
          </ul>
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}
