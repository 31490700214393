import React, {useState, useCallback, useEffect} from "react";

// components

import { getParamValue } from "utils/webutils";
import { getCookieValue } from "utils/webutils";
import { getFromWebserver } from "utils/webserver";
import LeaderboardCard from "components/Cards/LeaderboardCard";

export default function LeaderboardPage() {

  const [data, dataSet] = useState();

  const queryParams = new URLSearchParams(window.location.search);
  if (getParamValue(window.location.search) != null) {
    document.cookie =
      "guildId=" +
      queryParams.get("guildId") +
      ";";
  }

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
          <LeaderboardCard />
        </div>
      </div>
    </>
  );
}
